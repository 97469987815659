
import { IOption } from '@netfront/ui-library';

const getSelectOptions = (data: readonly string[]): IOption[] => {
  const options =  data.map((item) => {
    const option: IOption = {
      id: item,
      name: item,
      value: item,
    };

    return option;
  });

  return options;
};


export { getSelectOptions };
